import { TYPES } from "actions/index";
import { request, requestGet, requestDelete } from "network/request.js";

var isNonLyricsPending = false;

export function fetchAllNonLyrics(
  trimLanguageInfo = true,
  sinceTimestamp = null
) {
  return (dispatch, getState) => {
    const effectiveSinceTimestamp = state => {
      if (sinceTimestamp) {
        return sinceTimestamp;
      }

      if (state.nonLyrics && state.nonLyrics.nextFetchTimestamp) {
        return state.nonLyrics.nextFetchTimestamp;
      }

      return 0;
    };

    if (isNonLyricsPending) {
      return;
    }
    isNonLyricsPending = true;

    const sinceTimestampParam =
      "sinceTimestamp=" + effectiveSinceTimestamp(getState ? getState() : {});
    const path = trimLanguageInfo
      ? `/api/graph/allNonLyrics?${sinceTimestampParam}`
      : `/api/graph/allNonLyrics?trimLanguageInfo=false&${sinceTimestampParam}`;

    requestGet(path)
      .then(res => {
        isNonLyricsPending = false;
        dispatch(
          _receiveAllNonLyrics(
            res.data.nodeTypes,
            res.data.nodes,
            res.data.deletedNodes,
            trimLanguageInfo,
            res.data.nextFetchTimestamp,
            res.data.hasMore
          )
        );
      })
      .catch(error => {
        isNonLyricsPending = false;
      });
  };
}

export function saveNode(node, nodeId = 0) {
  const create = nodeId === 0;
  return dispatch => {
    return request({
      method: create ? "post" : "put",
      url: create ? `/api/graph/nodes` : `/api/graph/nodes/${nodeId}`,
      data: node
    }).then(res => {
      dispatch(_receiveNode(res.data));
      return res.data;
    });
  };
}

export function deleteNode(nodeId) {
  return dispatch => {
    return requestDelete(`/api/graph/nodes/${nodeId}`).then(res => {
      dispatch(_receiveDeleteNode(nodeId, res.data));
    });
  };
}

export function clearFetchedNodes() {
  return dispatch => {
    dispatch({
      type: TYPES.CLEAR_ALL_NODES,
      payload: {}
    });
  };
}

function _receiveAllNonLyrics(
  nodeTypes,
  nodes,
  deletedNodes,
  trimLanguageInfo,
  nextFetchTimestamp,
  hasMore
) {
  return dispatch => {
    dispatch({
      type: TYPES.RECEIVE_ALL_NON_LYRICS,
      payload: {
        nodeTypes,
        nodes,
        deletedNodes,
        nextFetchTimestamp
      }
    });

    if (hasMore) {
      dispatch(fetchAllNonLyrics(trimLanguageInfo, nextFetchTimestamp));
    }
  };
}

function _receiveNode(node) {
  return {
    type: TYPES.RECEIVE_NODE,
    payload: node
  };
}

function _receiveDeleteNode(nodeId, updatedNodes) {
  return {
    type: TYPES.DELETE_NODE,
    payload: {
      nodeId,
      updatedNodes
    }
  };
}
