export const tagCategorySelected = (bhajanTagDefinition, bhajanTag) => {
  console.log(`Selected: ${bhajanTagDefinition}, ${bhajanTag}`);
  return {
    action: "TAG_CATEGORY_SELECTED",
    payload: {
      bhajanTagDefinition,
      bhajanTag
    }
  };
};

const types = [
  "RECEIVE_FIREBASE_USER",
  "RECEIVE_PROFILE",

  "LANGUAGE_CHANGED",

  "TAG_CATEGORY_SELECTED",

  // bhajan tags & tag definitions
  "RECEIVE_ALL_NON_LYRICS",
  "CLEAR_ALL_NODES",

  "RECEIVE_NODES",
  "RECEIVE_NODE",

  "RECEIVE_BHAJAN_TAG",
  "RECEIVE_DELETE_BHAJAN_TAG",

  "RECEIVE_BHAJAN_TAGS",
  "RECEIVE_BHAJAN_TAG_DEFINITIONS",

  "RECEIVE_BHAJAN_TAG_DEFINITION",
  "RECEIVE_DELETE_BHAJAN_TAG_DEFINITION",

  // bhajans
  "RECEIVE_BHAJANS",
  "RECEIVE_BHAJAN",
  "RECEIVE_BHAJAN_LYRICS",
  "DELETE_BHAJAN",

  "RECEIVE_TRANSLITERATION"
];
export const TYPES = types.reduce((accum, type) => {
  accum[type] = type;
  return accum;
}, {});
