import React, { Component } from "react";
import { fetchAllNonLyrics } from "actions/nodes";
import {
  Confirm,
  Input,
  Message,
  Segment,
  Dropdown,
  Container,
  Form,
  Divider,
  Button
} from "semantic-ui-react";
import { request, requestGet, requestDelete } from "network/request";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { allLanguages, languageForLanguageCode } from "constants/languages";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { titleCase } from "util/stringUtil";

const LANGUAGE_FIELDS_PER_ROW = 5;
const NODE_TYPE_LYRICS = 2;

class EditBasicNode extends Component {
  state = {
    confirmDeleteOpen: false
  };

  componentDidMount() {
    if (!this.props.isChildComponent) {
      this.lyricsChildComponent = React.createRef();
      this.props.fetchAllNonLyrics(false);
    }

    // ugh terrible, but done is better than perfect
    this.componentDidUpdate();
  }

  render() {
    if (!this.state || !this.state.node || !this.props.graph.nodeTypes) {
      return null;
    }

    const formContent = (
      <div>
        <br />{" "}
        {/* ugh, seems this is the easiest way to introduce a bit of vertical spacing*/}
        {this._renderLanguageFields()}
        {this._renderNormalTextFields()}
        {this._renderNonLyricsEdgeNodeTypes()}
        {this._renderChildNodeIfApplicable()}
        {this._renderSaveButton()}
        {this._renderDeleteButton()}
      </div>
    );

    if (this.props.isChildComponent) {
      return formContent;
    } else {
      return (
        <Form>
          {formContent}
          <Confirm
            content={
              "Are you sure you want to delete this? This CANNOT BE UNDONE."
            }
            open={this.state.confirmDeleteOpen}
            onCancel={() => {
              this._closeConfirmDelete();
            }}
            onConfirm={() => {
              this._onConfirmDelete();
            }}
          />
        </Form>
      );
    }
  }

  _renderDeleteButton() {
    if (this.props.isChildComponent) {
      return null;
    }

    return (
      <Container textAlign="left">
        <Button
          type="submit"
          negative
          onClick={() => {
            this._openConfirmDelete();
          }}
        >
          Delete
        </Button>
      </Container>
    );
  }

  _renderLanguageFields() {
    const node = this.state.node;
    const nodeType = this.state.nodeType;

    const languageFields = nodeType.fields.filter(
      nodeType =>
        nodeType.dataType === "text" &&
        nodeType.editRenderType.includes("text") &&
        nodeType.isLanguageField
    );
    const fieldsByFieldGroup = languageFields
      .filter(field => !field.editingDisabled)
      .reduce((accum, field) => {
        const key = field.name.substring(2);
        if (!accum[key]) {
          accum[key] = [];
        }

        accum[key].push(field);
        return accum;
      }, {});
    const languageFieldGroups = Object.keys(fieldsByFieldGroup);

    return languageFieldGroups.reduce((accum, languageFieldGroup) => {
      const overwrittenFieldGroups = _.groupBy(
        fieldsByFieldGroup[languageFieldGroup],
        field => {
          const overwritten =
            node.data[`${field.name}Overwrite`] ||
            field.name.substring(0, 2) === allLanguages.TELUGU.languageCode;
          return overwritten ? "overwritten" : "auto";
        }
      );

      const elems = overwrittenFieldGroups.overwritten.map(field => {
        const languageCode = field.name.substring(0, 2);
        const language = languageForLanguageCode(languageCode);
        const formattedLanguage = titleCase(language);
        const formattedFieldName = `${formattedLanguage} ${field.name.substring(
          2
        )}`;
        const deleteButton = disabled => (
          <Button
            disabled={disabled}
            compact
            color="orange"
            icon="delete"
            onClick={() => {
              this._onToggleOverwrite(field);
            }}
          />
        );
        return (
          <div key={formattedFieldName}>
            <Form.Field>
              {deleteButton(languageCode === allLanguages.TELUGU.languageCode)}
              <label>
                {formattedFieldName}
                :&nbsp;&nbsp;&nbsp;
              </label>
              {field.editRenderType === "text" && (
                <Input
                  placeholder={formattedFieldName}
                  defaultValue={node.data[field.name]}
                  onChange={(event, { value }) => {
                    this._onFieldValueChange(field.name, value);
                  }}
                  className="autoWidth"
                />
              )}
              {field.editRenderType === "longtext" && (
                <Form.TextArea
                  placeholder={formattedFieldName}
                  defaultValue={node.data[field.name]}
                  onChange={(event, { value }) => {
                    this._onFieldValueChange(field.name, value);
                  }}
                />
              )}
            </Form.Field>
          </div>
        );
      });

      let addOverwriteElem;
      if (overwrittenFieldGroups.auto && overwrittenFieldGroups.auto.length) {
        const autoOptions = overwrittenFieldGroups.auto.map(field => {
          const languageName = titleCase(
            languageForLanguageCode(field.name.substring(0, 2))
          );
          return {
            text: languageName,
            value: field,
            key: field.name
          };
        });
        const select = (
          <span key={`Overwrite ${languageFieldGroup}`}>
            <Form.Field>
              <label>{`Overwrite ${languageFieldGroup}`}</label>
              <Dropdown
                placeholder={`Overwrite ${languageFieldGroup}`}
                selection
                selectOnBlur={false}
                options={autoOptions}
                onChange={(event, { value }) => {
                  this._onToggleOverwrite(value);
                }}
              />
            </Form.Field>
          </span>
        );
        addOverwriteElem = select;
      }

      accum.push(
        <Form.Group
          key={languageFieldGroup}
          widths={LANGUAGE_FIELDS_PER_ROW}
          inline
        >
          {elems &&
            elems.length > 0 && (
              <Container>
                <Segment secondary>
                  <Message>
                    <Message.Header>{languageFieldGroup} Fields</Message.Header>
                    <p>
                      The fields below represent one value (e.g. Name). If you
                      specify a Telugu value, this is auto-transliterated to
                      other languages. Sometimes, the transliteration is not
                      correct, so you can override the value for that specific
                      language.
                    </p>
                  </Message>
                  {elems}
                  <Divider />
                  {addOverwriteElem}
                </Segment>
              </Container>
            )}
        </Form.Group>
      );

      return accum;
    }, []);
  }

  _renderNormalTextFields() {
    const nodeType = this.state.nodeType;
    const node = this.state.node;

    const normalTextFields = nodeType.fields.filter(
      nodeType =>
        nodeType.dataType === "text" &&
        nodeType.editRenderType.includes("text") &&
        !nodeType.isLanguageField
    );

    return (
      normalTextFields.length > 0 && (
        <Form.Group inline>
          <Container>
            <Segment>
              {normalTextFields.map(field => {
                const fieldName = titleCase(field.name);
                return (
                  <Form.Field key={field.name}>
                    <label>{fieldName}</label>
                    <Input
                      placeholder={fieldName}
                      defaultValue={node.data[field.name]}
                      onChange={(event, { value }) => {
                        this._onFieldValueChange(field.name, value);
                      }}
                    />
                  </Form.Field>
                );
              })}
            </Segment>
          </Container>
        </Form.Group>
      )
    );
  }

  _renderNonLyricsEdgeNodeTypes() {
    const languageCode = this.props.userSettings.selectedViewLanguage;
    const nameKey = `${languageCode}Name`;

    const node = this.state.node;
    const nodeType = this.state.nodeType;

    const nonLyricsEdgeNodeTypes = nodeType.edges
      .filter(edge => edge.name !== "lyrics")
      .map(edge => {
        return this.props.graph.nodeTypes.find(
          nodeType => nodeType.name === edge.name
        );
      });

    return (
      nonLyricsEdgeNodeTypes.length > 0 && (
        <Container>
          <Segment>
            <Message>
              <Message.Header>Linked Data</Message.Header>
              <p>
                Other pieces of data that this bhajan can link to. For example,
                you can define a ragam once and have multiple bhajans link to
                it.
              </p>
            </Message>
            {nonLyricsEdgeNodeTypes.map(edgeNodeType => {
              // create a dropdown element, where options are all of those elems
              const edge = node.edges.find(
                edge => edge.name === edgeNodeType.name
              );
              const edgeNodes = this.props.graph[edgeNodeType.pluralName] || [];
              const options = edgeNodes.map(node => {
                return {
                  text: node.data[nameKey]
                    ? node.data[nameKey]
                    : node.data.name,
                  value: node.nodeId,
                  key: node.nodeId
                };
              });

              /**
               * Don't render these because they are not intended to be edited
               * via the web UI.
               */
              const edgeNodeNameLower = edgeNodeType.name.toLowerCase();
              if (
                edgeNodeNameLower == "compositionlanguage" ||
                edgeNodeNameLower == "audible" ||
                edgeNodeNameLower == "meaning" ||
                edgeNodeNameLower == "bb"
              ) {
                return null;
              }
              return (
                <Form.Field inline key={edgeNodeType.nodeTypeId}>
                  <label>{titleCase(edgeNodeType.name)}</label>
                  <Dropdown
                    placeholder={titleCase(edgeNodeType.name)}
                    search
                    selection
                    selectOnBlur={false}
                    options={options}
                    defaultValue={edge && edge.nodeId}
                    onChange={(event, { value }) => {
                      this._onEdgeChange({
                        name: edgeNodeType.name,
                        nodeId: value
                      });
                    }}
                  />
                </Form.Field>
              );
            })}
          </Segment>
        </Container>
      )
    );
  }

  _renderChildNodeIfApplicable() {
    const lyricsEdgeType = this.state.nodeType.edges.find(
      edgeType => edgeType.name === "lyrics"
    );
    const lyricsEdge = this.state.node.edges.find(
      edge => edge.name === "lyrics"
    );
    if (!lyricsEdgeType) {
      return null;
    }

    return (
      <EditBasicNode
        ref={this.lyricsChildComponent}
        isChildComponent={true}
        graph={this.props.graph}
        userSettings={this.props.userSettings}
        nodeId={lyricsEdge ? lyricsEdge.nodeId : 0}
        nodeTypeId={NODE_TYPE_LYRICS}
      />
    );
  }

  _renderSaveButton() {
    if (this.props.isChildComponent) {
      return null;
    }

    return (
      <Container textAlign="right">
        <Button
          type="submit"
          positive
          onClick={() => {
            this.onSaveTapped();
          }}
        >
          Save
        </Button>
      </Container>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state && (this.state.fetchingData || this.state.node)) {
      return;
    }

    const nodeId = this.props.nodeId;
    if (nodeId !== 0) {
      requestGet(`/api/graph/nodes/${nodeId}?trimLanguageInfo=false`).then(
        res => {
          const node = res.data;
          const nodeType = this.props.graph.nodeTypes.find(
            nodeType => nodeType.nodeTypeId === node.nodeTypeId
          );
          this.setState({
            node,
            nodeType
          });
        }
      );
      this.setState({
        fetchingData: true
      });
    } else {
      const nodeType = this.props.graph.nodeTypes.find(
        nodeType => nodeType.nodeTypeId === this.props.nodeTypeId
      );
      this.setState({
        fetchingData: true,
        node: {
          nodeId: 0,
          nodeTypeId: this.props.nodeTypeId,
          data: {},
          edges: []
        },
        nodeType
      });
    }
  }

  _onToggleOverwrite(value) {
    const node = Object.assign({}, this.state.node);
    node.data[`${value.name}Overwrite`] = !node.data[`${value.name}Overwrite`];
    this.setState({
      node
    });
  }

  _onFieldValueChange(fieldName, value) {
    const node = Object.assign({}, this.state.node);
    node.data[fieldName] = value;

    const nodeType = this.props.graph.nodeTypes.find(
      nodeType => nodeType.nodeTypeId === this.props.nodeTypeId
    );
    const overwriteFieldName = `${fieldName}Overwrite`;
    const field = nodeType.fields.find(
      field => field.name === overwriteFieldName
    );
    if (field) {
      node.data[overwriteFieldName] = !!value;
    }

    this.setState({
      node
    });
  }

  _onEdgeChange(edge) {
    const edgeName = edge.name;
    const node = Object.assign({}, this.state.node);
    const newEdges = node.edges.filter(edge => edge.name !== edgeName);
    newEdges.push(edge);
    node.edges = newEdges;
    this.setState({
      node
    });
  }

  onSaveTapped(isChildComponent = false, callback) {
    const node = this.state.node;
    const create = node.nodeId === 0;
    const fetchData = this.props.fetchAllNonLyrics;
    const createOrSaveNode = () => {
      request({
        method: create ? "post" : "put",
        url: `/api/graph/nodes/${node.nodeId}`,
        data: node
      }).then(res => {
        if (isChildComponent) {
          callback(res.data);
        } else {
          fetchData(false);
          const newNodeId = res.data.nodeId;
          if (this.state.nodeType.name === "bhajan") {
            this.props.history.push(`/nodes/${newNodeId}`);
          } else {
            this.props.history.push(`/nodeTypes/${node.nodeTypeId}`);
          }
        }
      });
    };

    if (this.props.isChildComponent) {
      createOrSaveNode();
    } else if (this.lyricsChildComponent && this.lyricsChildComponent.current) {
      this.lyricsChildComponent.current.onSaveTapped(true, lyricsNode => {
        const lyricsNodeId = lyricsNode.nodeId;
        const edges = node.edges.filter(edge => edge.name !== "lyrics");
        edges.push({
          name: "lyrics",
          nodeId: lyricsNodeId
        });
        node.edges = edges;

        createOrSaveNode();
      });
    } else {
      createOrSaveNode();
    }
  }

  _openConfirmDelete() {
    this.setState({
      confirmDeleteOpen: true
    });
  }

  _closeConfirmDelete() {
    this.setState({
      confirmDeleteOpen: false
    });
  }

  _onConfirmDelete() {
    this.onDeleteTapped();
  }

  onDeleteTapped(isChildComponent = false, callback) {
    const node = this.state.node;
    const deleteNode = () => {
      requestDelete(`/api/graph/nodes/${node.nodeId}`).then(res => {
        if (isChildComponent) {
          callback(res, node);
        } else {
          this.props.history.push(`/browse`);
        }
      });
    };

    if (this.props.isChildComponent) {
      deleteNode();
    } else if (this.lyricsChildComponent && this.lyricsChildComponent.current) {
      this.lyricsChildComponent.current.onDeleteTapped(
        true,
        (res, lyricsNode) => {
          node.edges = node.edges.filter(
            edge => edge.nodeId === lyricsNode.nodeId
          );
          deleteNode();
        }
      );
    } else {
      deleteNode();
    }
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(EditBasicNode)
);
