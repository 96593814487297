import { TYPES } from "actions/index";
import update from "react-addons-update";
import _ from "lodash";
import { allLanguages } from "constants/languages";
import * as Cookies from "js-cookie";
import * as COOKIES from "constants/cookies";
import { stripNonSearchableCharacters } from "util/stringUtil";

const DEFAULT_STATE = {
  nodeTypes: [],
  nodes: [],
  bhajans: [],
  ragams: [],
  talams: [],
  composers: [],
  deities: [],
  meanings: [],
  nextFetchTimestamp: 0
};

export default (state = _.cloneDeep(DEFAULT_STATE), action) => {
  switch (action.type) {
    case TYPES.CLEAR_ALL_NODES:
      return _.cloneDeep(DEFAULT_STATE);
    case TYPES.RECEIVE_ALL_NON_LYRICS:
      const existingNodes = state.nodes || [];

      const deletedNodes = action.payload.deletedNodes || [];
      const deletedNodeIds = new Set(deletedNodes.map(n => n.nodeId));

      /**
       * TRICKY: Add incoming nodes first to ensure we always prioritize incoming
       * nodes in case there is a conflict.
       */
      const updatedNodes = _.uniqBy(
        [...action.payload.nodes, ...existingNodes],
        "nodeId"
      ).filter(n => !deletedNodeIds.has(n.nodeId));
      const ret = _groupNodes(action.payload.nodeTypes, updatedNodes);
      ret.nextFetchTimestamp = action.payload.nextFetchTimestamp;
      return ret;
    case TYPES.RECEIVE_NODES:
      return action.payload;
    case TYPES.RECEIVE_NODE:
      return _updateSingleNode(state, action.payload);
    case TYPES.DELETE_NODE:
      return _deleteNode(
        state,
        action.payload.nodeId,
        action.payload.updatedNodes
      );
    default:
      return state;
  }
};

function _groupNodes(nodeTypes, nodes) {
  const nodesWithName = _autoAddName(nodes);
  const nodeTypesByNodeTypeId = _.keyBy(nodeTypes, "nodeTypeId");
  const sortedNodes = _.sortBy(nodesWithName, "data.normalizedName");
  const nodesByType = sortedNodes.reduce((accum, node) => {
    const nodeType = nodeTypesByNodeTypeId[node.nodeTypeId];
    const keyName = nodeType.pluralName;
    if (!accum[keyName]) {
      accum[keyName] = [];
    }

    accum[keyName].push(node);
    return accum;
  }, {});
  nodesByType.nodeTypes = nodeTypes;
  nodesByType.nodes = sortedNodes;
  return nodesByType;
}

function _autoAddName(nodes) {
  // TRICKY: we can't get another reducer from inside this reducer, so we duplicated this logic :(
  const selectedViewLanguage =
    Cookies.get(COOKIES.selectedViewLanguage) ||
    allLanguages.ENGLISH.languageCode;
  return nodes.map(node => {
    const key = `${selectedViewLanguage}Name`;
    if (node.data[key] && !node.data.name) {
      node.data.name = node.data[key];

      if (node.data.name) {
        node.data.normalizedName = stripNonSearchableCharacters(node.data.name);
      }
    }
    return node;
  });
}

function _updateSingleNode(state, node) {
  const nodes = [...state.nodes];
  const index = nodes.findIndex(n => n.nodeId === node.nodeId);

  if (index >= 0) {
    const foundNode = nodes[index];
    nodes[index] = node;
  } else {
    nodes.push(node);
  }

  return {
    ...state,
    nodes: _autoAddName(nodes)
  };
}

function _deleteNode(state, nodeId, updatedNodes = []) {
  const deletedNodeIds = new Set(updatedNodes.map(node => node.nodeId));
  deletedNodeIds.add(nodeId);

  return {
    ...state,
    nodes: state.nodes.filter(node => !deletedNodeIds.has(node.nodeId))
  };
}
